<template>

	<div style="background: #fff;padding: 15px;overflow:hidden" class="ml-goodList">
		<div class="filter-item">
			<label class="label">关键字: </label>
			<el-input v-model="searchKey" placeholder="企业微信群名称" style="width:250px;margin-right: 10px;" clearable
			 @keyup.enter.native="handleSearch"></el-input>
			<el-button type='primary' @click='handleSearch'>查询</el-button>
			<el-button @click='handleExport' v-loading='exportloading'>导出</el-button>
		</div>
		<!-- 表格 -->
		<div class="table-container" style="margin-top:10px;">
			<el-table :data="tableList" style="width: 100%;" v-loading="loading" @sort-change="sortChange">
				<el-table-column prop="GroupName" label="企业微信群名称" width='350px'>
					<template slot-scope="scope">
						<div style='width:300px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;'>{{scope.row.GroupName}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="" label="群主" width='350px'>
					<template slot-scope="scope">
						<span>{{scope.row.EmployeeName}}（{{scope.row.EmploueePhone}}）</span>
					</template>
				</el-table-column>
				<el-table-column prop="MemberCount" label="群成员（人）"></el-table-column>
				<el-table-column prop="CommunityCount" sortable>
					<template slot="header" slot-scope="scope">
						群总订单
						<el-tooltip class="item" effect="dark" content="绑定群聊的成员，支付成功的订单数量" placement="top">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="CommunityPerformance" sortable>
					<template slot="header" slot-scope="scope">
						群总业绩（元）
						<el-tooltip class="item" effect="dark" content="绑定群聊的成员，订单实付金额，包含运费" placement="top">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>

	</div>

</template>

<script>
	import apiList from '@/api/other'
	import config from '@/config/index'
	export default {
		data() {
			return {
				loading: false,
				exportUrl: config.EXPORT_URL,
				tableList: [],
				searchKey: '',
				exportloading: false,
				OrderBy: '',
				OrderByType: '',
				IsAsc: '',
				page: {
					total: 0,
					size: 20,
					current: 1
				}
			}
		},

		created() {
			this.getInfo()
		},
		methods: {
			handleSearch(){
				this.page.current = 1
				this.getInfo()
			},
			async getInfo() {
				this.loading = true;
				try {
					let result = await apiList.communityGroupData({
						KeyWords: this.searchKey,
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
						OrderBy: this.OrderBy,
						OrderByType : this.OrderByType,
						IsAsc: this.IsAsc,
					})
					if (result.IsSuccess) {
						this.tableList = result.Result.Results
						this.page.total = result.Result.Total
					}
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},
			// 导出
			async handleExport() {
				this.exportloading = true;
				try {
					let url = this.exportUrl + '/pc/communityGroup/communityGroupDataExport?' +
						'&KeyWords=' + this.searchKey
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.exportloading = false;
				}
			},
			sortChange(column, prop, order) {
				if(column.prop == "CommunityPerformance"){
					this.OrderByType = 2
				}else if(column.prop == "CommunityCount"){
					this.OrderByType = 1
				}
				if (column.order == "ascending") {
					this.IsAsc = true
					this.OrderBy = column.prop
				} else if (column.order == "descending") {
					this.IsAsc = false
					this.OrderBy = column.prop
				} else {
					this.IsAsc = ''
					this.OrderBy = ''
					this.OrderByType = 0
				}
				this.page.current = 1;
				this.getInfo()
			},
			handleSizeChange(val) {
				this.page.size = val
				this.getInfo()
			},

			handleCurrentChange(val) {
				this.page.current = val
				this.getInfo()
			}
		}
	};
</script>

<style lang="less" scoped>
	.product-message {
		display: flex;
	}
</style>
